<template>
    <div>
        <v-container fluid class="pt-0 mt-0">
            <HeaderAzul titulo="Meus" subtitulo="Livros"/>
        </v-container>
        <v-container fluid>
            <v-row v-if="livros.length">
                <v-col cols="12" sm="6" xl="3" v-for="(l, i) in livros" :key="i">
                    <v-card :to="`/livro_fisico/completo/${l.id_livro}`">
                        <v-card-text class="pt-3 mt-0">
                            <v-row>
                                <v-col cols="7">
                                    <h2><strong>{{l.title}}</strong></h2>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-alert v-else>Biblioteca vazia</v-alert>
        </v-container>
    </div>
</template>

<script>

import {mapState} from "vuex";
import HeaderAzul from "@/Views/Estrutura/HeaderAzul";
export default {
    name: "LivroFisico",
    components: {HeaderAzul},
    data: () => {
        return {
            livros : []
        }
    },
    computed : {
        ...mapState(['usuarioSessao']),

    },
    methods : {
        init() {
            let c = []
            if (this.usuarioSessao.livro1) {
                c.push({
                    id_livro : 1,
                    title : '1. New Energy'
                })
            }
            if (this.usuarioSessao.livro2) {
                c.push({
                    id_livro : 2,
                    title : '2. New Decision'
                })
            }
            if (this.usuarioSessao.livro3) {
                c.push({
                    id_livro : 3,
                    title : '3. New Vision'
                })
            }
            if (this.usuarioSessao.livro4) {
                c.push({
                    id_livro : 4,
                    title : '4. New Lifestyle'
                })
            }
            this.livros = c
        }
    },
    created() {
        this.init()
    }
}
</script>

<style scoped>
    h2 {font-size: 30px; font-weight: lighter; line-height: 110%; color: #00436F;}
    h2 strong {font-weight: bold;}
</style>